// src/App.js

import React, { useState } from 'react';
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './Dashboard';
import logo from './logo.png';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [empowerCount, setEmpowerCount] = useState(0);
  const apiUrl = process.env.REACT_APP_API_URL; // 从环境变量获取 API URL

  // 控制是否显示登录页面
  const [showLogin, setShowLogin] = useState(true);

  const handleLogin = async () => {
    if (!username || !password) {
      toast.error('账户或密码不可为空'); // 使用 Toast 提示错误信息
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const { message, userInfo } = await response.json(); // 获取后端返回的消息和用户信息

      if (response.ok) {
        setEmpowerCount(userInfo.empowerCount); // 设置授权次数
        setIsLoggedIn(true); // 更新登录状态
        toast.success('登录成功'); // 显示成功消息

        // 添加过渡动画，设置 1000ms 后切换界面
        setTimeout(() => {
          setShowLogin(false); // 切换到主页面
        }, 1000);

      } else {
        toast.error(message); // 显示错误消息
      }
    } catch (error) {
      console.error('登录失败:', error);
      toast.error('登录失败，请稍后再试');
    }
  };

  return (
    <div className="App">
      <ToastContainer position="top-center" autoClose={3000} />
      {showLogin ? (
        <div className={`transition-container ${isLoggedIn ? 'fade-out' : ''}`}>
          <header className="App-header">
            <img src={logo} className="Header-logo" alt="logo" />
            <div className="user-info">
              <span className="login-status">未登录</span>
            </div>
          </header>
          <div className="logo-container">
            <img src={logo} className="App-logo" alt="logo" />
            <h1 className="App-title">WeTheme授权网</h1>
          </div>
          <div className="form-container">
            <input
              id="userName"
              type="text"
              placeholder="账户"
              className="input-field"
              value={username}
              onChange={(e) => setUsername(e.target.value)} // 设置用户名
            />
            <input
              id="passWords"
              type="password"
              placeholder="密码"
              className="input-field"
              value={password}
              onChange={(e) => setPassword(e.target.value)} // 设置密码
            />
            <button className="login-button" onClick={handleLogin}>
              登录
            </button>
          </div>
        </div>
      ) : (
        <Dashboard username={username} empowerCount={empowerCount} />
      )}
    </div>
  );
}

export default App;
